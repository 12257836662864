import "./styles/app.scss";

/**
 * External libraries
 */
const $ = require("jquery");

import "bootstrap";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import * as Chart from "chart.js/auto";
window.Chart = Chart.default;

// Import TinyMCE
import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import "tinymce/icons/default";
import "tinymce/models/dom";
import "tinymce/plugins/code";
import "tinymce/plugins/autolink";
import "tinymce/plugins/table";
import "tinymce/plugins/link";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/image";

/**
 * Our own components
 */
import AddressesAutocomplete from "./components/addressesAutocomplete";
import ibanInputFormatter from "./components/ibanInputFormatter";

const initAutocompletes = () => {
  $(".address-autocomplete").each(function () {
    new AddressesAutocomplete($(this));
  });
};
const initIbanFields = () => {
  $(".iban").each(function () {
    new ibanInputFormatter($(this));
  });
};

$(document).ready(function () {
  initAutocompletes();
  //initIbanFields();
});

window.initAutocompletes = initAutocompletes;
window.initIbanFields = initIbanFields;

/**
import $ from 'jquery';

// require('bootstrap/dist/js/bootstrap');
// require('@fortawesome/fontawesome-free/css/all.min.css');
// require('@fortawesome/fontawesome-free/js/all.js');
require('bootstrap4-toggle');

$('*[data-href]').on("click",function(){
    window.location = $(this).data('href');
    return false;
});

$(document).ready(function () {
    // nothing
});
**/
