require('jquery-ui/ui/widgets/autocomplete');
const $ = require('jquery');

class AddressAutocomplete {
    constructor(addressField)
    {
        this.addressField = addressField;
        this.init();
    }

    init(addressFieldName, cityFieldName, postalCodeFieldName)
    {
        const that = this;
        this.addressField.autocomplete({
            delay: 500,
            minLength: 3,
            source: function (request, response) {
                $.ajax({
                    url: "https://api-adresse.data.gouv.fr/search/",
                    data: {q: request.term},
                    dataType: "json",
                    success: function (data) {
                        response($.map(data.features, function (item) {
                            return {
                                label: item.properties.label,
                                value: item.properties.name,
                                city: item.properties.city,
                                postalCode: item.properties.postcode,
                            }
                        }));
                    }
                });
            },
            select: function (event, ui) {
                that.getCityField().val(ui.item.city);
                that.getPostalCodeField().val(ui.item.postalCode);
            }
        });
    }

    getBaseFieldName()
    {
        const addrFieldName = this.addressField.attr('name');
        return addrFieldName.replace('[address][address]', '[address]');
    }

    getCityField()
    {
        const fieldName = this.getBaseFieldName() + '[city]';
        return $('input[name="' + fieldName + '"');
    }

    getPostalCodeField()
    {
        const fieldName = this.getBaseFieldName() + '[postal_code]';
        return $('input[name="' + fieldName + '"');
    }
}

module.exports = AddressAutocomplete;