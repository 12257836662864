const $ = require('jquery');

/**
 * Format iban fields in a cute iban format
 */

class ibanInputFormatter {
    constructor(ibanField)
    {
        this.ibanField = ibanField;
        this.init();
    }

    init()
    {
        const that = this;

        $(this.ibanField).on('input', function(e) {
            let target = e.target, position = target.selectionEnd, length = target.value.length;
            target.value = target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
            target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0);
        }).trigger('input');
    }
}

module.exports = ibanInputFormatter;